import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export const newsData = () => {
    return useStaticQuery(
        graphql`
          {
            all: allDirectusPost(sort: {fields: date_created, order: DESC}) {
              edges {
                node {
                  type {
                    data {
                      id
                      name
                    }
                  }
                  title
                  content
                  banner
                  date
                  slug
                }
              }
            }
            event: allDirectusPost(filter: {type: {data: {id: {eq: 1}}}}, sort: {fields: date_created, order: DESC}) {
              edges {
                node {
                  type {
                    data {
                      id
                      name
                    }
                  }
                  title
                  content
                  banner
                  date
                  slug
                }
              }
            }
            update: allDirectusPost(filter: {type: {data: {id: {eq: 2}}}}, sort: {fields: date_created, order: DESC}) {
              edges {
                node {
                  type {
                    data {
                      id
                      name
                    }
                  }
                  title
                  content
                  banner
                  date
                  slug
                }
              }
            }
            slider: allDirectusTopSlider {
              edges {
                node {
                  image
                }
              }
            }
          }
        `
    )
}