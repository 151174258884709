import React, { Component } from 'react';
import { Link } from 'gatsby';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Slider from "react-slick";
import Helmet from "react-helmet";

// Component
import config from 'data/SiteConfig';
import Layout from 'layout';
import Top from './top'
import { newsData } from 'data/Hooks';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as tabActions from 'redux/actions/tab';

class News extends Component {

    componentDidMount() {
        const { pageContext, tabActions } = this.props;
        tabActions.change_news_tab(pageContext.pages);
    }

    componentWillReceiveProps() {
        if (typeof window !== 'undefined') {
            window.addEventListener('popstate', (event) => {
                window.location.reload();
            });
        }
    }

    changeTab = (type) => {
        if (typeof window !== 'undefined') {
            window.history.pushState({}, document.title, `${window.location.origin}/news/${type}`);
        }
        this.props.tabActions.change_news_tab(type);
        this.props.tabActions.put_data('page', 1);
    }

    generateTab = (tab) => {
        return config.newsTab.map((data, x) =>
            <div key={x} className={(tab === data) ? `active` : ``} onClick={() => this.changeTab(data)}>
                <span>{data}</span>
            </div>
        )
    }

    generateData = (tab) => {
        const { data } = this.props;
        const { page } = this.props.tab;

        let show_count = 5;
        let page_count = data[tab].edges.length <= show_count ?
            1 : (data[tab].edges.length % show_count === 0) ?
                data[tab].edges.length / show_count : Math.floor(data[tab].edges.length / show_count) + 1;
        let start = page === 1 ? 0 : show_count * (page - 1);
        let end = start + show_count;

        let new_data = [...data];
        new_data = new_data[0][tab].edges.slice(start, end);
        return { newsData: new_data, page_count }
    }

    strip = (html) => {

        let substract = 300;

        if (typeof window !== 'undefined') {
            if (window.innerWidth <= 820) {
                substract = 195;
            }
        }

        let text = html.replace(/<[^>]*>?/gm, '');
        let length = text.length;

        if (length > substract) {
            text = text.substring(0, substract);
        }

        text += "...";

        return text;
    }

    handlePageClick = page => {
        const selected = page.selected;
        const { put_data } = this.props.tabActions;
        put_data('page', selected + 1)
    }

    generateTabContent = (tab) => {
        const { newsData, page_count } = this.generateData(tab)
        const { page } = this.props.tab;

        if (newsData.length < 1) {
            return
        }

        let list = config.newsTab.map((data, x) => {
            return (
                <div key={x} className={(tab === data) ? `active` : ``}>
                    <ul>
                        {
                            newsData.map((datas, y) =>
                                <li key={y}>
                                    <Link to={`/post/${datas.node.slug}`}>
                                        <div className="img">
                                            <img src={datas.node.banner} alt="" className="img-fluid" />
                                        </div>
                                        <div className="text">
                                            <div className="top_info">
                                                <span className="date">{moment(datas.node.date).format('DD/MM/YY')}</span>
                                                <span className="tag">{datas.node.type.data.name}</span>
                                                <span className="line"></span>
                                            </div>
                                            <div className="detail">
                                                <h3 className="title">{datas.node.title}</h3>
                                                <p dangerouslySetInnerHTML={{ __html: this.strip(datas.node.content) }} />
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            )
                        }
                    </ul>
                    <ReactPaginate
                        previousLabel={'<<'}
                        nextLabel={'>>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={page_count}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        forcePage={page - 1}
                    />
                </div>
            )
        })
        return list;
    }

    generateSlide = () => {
        const { slider } = this.props.data;
        return slider.edges.map((data, x) =>
            <div key={x} className="banner">
                <img src={data.node.image} alt="" className="img-fluid" />
            </div>
        )
    }

    render() {
        const { tab } = this.props.tab;
        const settings = {
            dots: true,
            dotsClass: "slick-dots slick-thumb",
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false
        };

        return (
            <Layout>
                <Helmet title={"News - " + config.siteTitle} />
                <div className="news-container" >
                    <div className="top"></div>
                    <Slider {...settings}>
                        {this.generateSlide()}
                    </Slider>
                    <div className="news_layout">
                        <div className="box_news">
                            <Top />
                            <div id="News" className="box">
                                <div className="tab_title">
                                    {this.generateTab(tab)}
                                </div>
                                <div className="tab_content">
                                    {this.generateTabContent(tab)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }

}

function mapStateToProps(state) {
    return { tab: state.tab }
}

function mapDispatchToProps(dispatch) {
    return {
        tabActions: bindActionCreators(tabActions, dispatch)
    };
}

const NewsLayout = connect(mapStateToProps, mapDispatchToProps)(News);

export default props => {
    const data = newsData();
    return (
        <NewsLayout {...props} data={data} />
    )
}